<template>
  <div :class="[{ 'd-none': !modalVisible }]">
    <div
      v-if="modalVisible"
      ref="modal"
      :class="['modal fade m-modal', modalCssClass]"
      :data-autofocus="autofocus ? null : 'false'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div
        ref="dialogEl"
        class="modal-dialog"
        role="document"
      >
        <div :class="['modal-content', {'with-subtitle': hasSubtitle}]">
          <div class="modal-header">
            <slot name="modal-header">
              <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"></span></button>
              <h4 class="modal-title"><%= t '.folder_modal.title_create' %></h4> -->
              <h5
                id="modalLabel"
                class="modal-title"
              >
                {{ modalTitle }}
              </h5>
            </slot>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div
            v-if="hasSubtitle"
            class="modal-sub-title"
          >
            {{ modalSubtitle }}
          </div>
          <div class="modal-body">
            <loading-indicator
              v-if="isLoading"
              :message="loadingMessage"
            />
            <!-- <div v-if="error && showModalError" class="alert alert-danger" role="alert">{{ error }}</div> -->
            <div
              v-if="showErrorMessage"
              class="alert alert-danger"
            >
              {{ errorMessage }}
              <a
                v-if="hasErrorDetails && !errorDetailsVisible && !errorDetailsExpanded"
                href="#"
                class="float-right"
                @click.prevent="showErrorDetails"
              >
                <small>{{ $t('form_errors.view_details') }}</small>
              </a>
              <button
                v-else
                type="button"
                class="close"
                aria-label="Close"
                @click.prevent="hideError"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <template v-if="hasErrorDetails && (errorDetailsVisible || errorDetailsExpanded)">
                <hr>
                <ul>
                  <li
                    v-for="(message, index) of errorDetails"
                    :key="index"
                  >
                    {{ message }}
                  </li>
                </ul>
              </template>
            </div>
            <slot name="modal-content">
              <div
                v-if="formDetails && formDetails.html"
                ref="formWrap"
                v-html="formDetails.html"
              />
            </slot>
          </div>
          <div
            v-if="withFooter"
            class="modal-footer"
          >
            <slot name="modal-footer">
              <!-- <button v-if="deleteVisible" type="button" class="btn btn-sm btn-danger" @click="deleteItem">Delete</button> -->
              <button
                v-if="showSubmitButton"
                type="button"
                class="m-btn m-btn-primary"
                @click="submitAction"
              >
                {{ submitButtonTitle }}
              </button>
              <button
                v-if="readOnlyForm || (hasForm && formEl == null)"
                type="button"
                class="m-btn m-btn-cancel"
                data-dismiss="modal"
              >
                {{ $t('modal.close_button') }}
              </button>
              <button
                v-else
                type="button"
                class="m-btn m-btn-cancel"
                data-dismiss="modal"
              >
                {{ closeButtonTitle }}
              </button>
              <!-- <button v-if="showFormSubmit" type="button" @click="submitForm" class="btn btn-sm btn-primary" :disabled="isLoading">Save</button> -->
              <button
                v-if="withDeleteButton"
                class="m-btn m-btn-destroy ml-auto"
                @click.prevent="showDeleteConfirmation"
              >
                {{ $t('modal.delete_button') }}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="modalVisible"
      class="modal-backdrop fade show"
    />
    <slot name="additional-content" />
  </div>
</template>

<script setup lang="ts">
  import { computed, nextTick, ref, useTemplateRef } from 'vue'
  import $ from 'jquery';
  import { isPresent, isNonEmptyString } from '../js-utils'
  import { initializeFormFields, autofocusInputField } from '../site-utils'
  import axios from 'axios'
  import Rails from '@rails/ujs'
  import Jsona from 'jsona'
  import type { TJsonaModel } from 'jsona/lib/JsonaTypes'
  import type { ModalOptions } from '../types'
  import URI from 'urijs'
  import LoadingIndicator from './loading-indicator.vue'
  import { useTranslation } from "i18next-vue";

  const { t: $t } = useTranslation()

  export interface DataRequestOptions {
    url: string;
    params?: any;
    requestMethod?: 'get' | 'GET' | 'post' | 'POST';
    jsonapiResponse: boolean;
  }

  // export default (Vue as VueConstructor<
  //   Vue & {
  //     $refs: {
  //       formWrap?: HTMLElement;
  //     };
  //   }
  // >).extend({

  const props = defineProps({
    modalTitle: { default: '', type: String },
    modalSubtitle: { default: '', type: String },
    modalCssClass: { default: null, type: String },
    saveBtnTitle: { default: '', type: String },
    closeBtnTitle: { default: '', type: String },
    allowClose: { default: true, type: Boolean },
    allowKeyboardClose: { default: false, type: Boolean },
    autofocus: { default: true, type: Boolean },
    withFooter: { default: true, type: Boolean },
    errorDetailsExpanded: { default: false, type: Boolean },
  })

  const emit = defineEmits<{
    (e: 'modal-hidden'): void
    (e: 'modal-shown'): void
    (e: 'should-hide'): void
    (e: 'form-data-loaded', data: TJsonaModel): void
    (e: 'created', data: TJsonaModel, contextData: any): void
    (e: 'form-loaded', formEl: HTMLFormElement | HTMLDivElement): void
    (e: 'data-loaded', data: any): void
    (e: 'delete-confirmation', url: string): void
  }>()

  const modalEl = useTemplateRef<HTMLDivElement | null>('modal')
  const formWrapEl = useTemplateRef<HTMLDivElement | null>('formWrap')

  const modalVisible = ref<boolean>(false)
  const isLoading = ref<boolean>(false)
  const loadingMessage = ref<string>('')
  const formUrl = ref<string | null>(null)
  const formParams = ref<any>(null)
  const formDetails = ref<TJsonaModel | null>(null)
  const formEl = ref<HTMLFormElement | null>(null)
  const readOnlyForm = ref<boolean>(false)
  const formLoadRequestMethod = ref<'get' | 'post' | 'GET' | 'POST' | null>(null)
  const formLoadJsonapiRequest = ref<boolean>(false)
  const errorMessage = ref<string | null>(null)
  const errorDetails = ref<string[]>([])
  const errorDetailsVisible = ref<boolean>(false)
  const contextData = ref<any>(null)
  const loadDataOptions = ref<DataRequestOptions | null>(null)


  const hasForm = computed((): boolean => {
    return isPresent(formUrl.value)
  })

  const showSubmitButton = computed((): boolean => {
    if (readOnlyForm.value) {
      return false
    }

    return !!(formDetails.value && isPresent(formDetails.value.html) && formEl.value);
  })

  const submitButtonTitle = computed((): string => {
    if (formDetails.value && isNonEmptyString(formDetails.value.submitTitle)) {
      return formDetails.value.submitTitle
    }
    if (isNonEmptyString(props.saveBtnTitle)) {
      return props.saveBtnTitle
    } else {
      return $t('modal.save_button')
    }
  })

  const closeButtonTitle = computed((): string => {
    if (isNonEmptyString(props.closeBtnTitle)) {
      return props.closeBtnTitle
    } else {
      return $t('modal.cancel_button')
    }
  })

  const showErrorMessage = computed((): boolean => {
    return isPresent(errorMessage.value)
  })

  const hasErrorDetails = computed((): boolean => {
    return isPresent(errorMessage.value) && errorDetails.value.length > 0
  })

  const hasSubtitle = computed((): boolean => {
    return isPresent(props.modalSubtitle)
  })

  const deleteConfirmationUrl = computed((): string | null => {
    const deleteConfirmationUrl	= formDetails.value?.meta?.deleteConfirmationUrl
    if (isNonEmptyString(deleteConfirmationUrl)) {
      return deleteConfirmationUrl
    }
    return null
  })

  const withDeleteButton = computed((): boolean => {
    return deleteConfirmationUrl.value != null
  })

  const showForm = (aFormUrl: string, aFormParams?: any, modalOptions?: ModalOptions, aContextData?: any): void => {
    formUrl.value = aFormUrl
    formParams.value = aFormParams
    formLoadRequestMethod.value = null
    readOnlyForm.value = modalOptions?.readonly || false
    contextData.value = aContextData
    if (modalOptions) {
      if (modalOptions.requestMethod) {
        formLoadRequestMethod.value = modalOptions.requestMethod
      }
      if (modalOptions.jsonapiRequest != null && typeof modalOptions.jsonapiRequest !== 'undefined') {
        formLoadJsonapiRequest.value = modalOptions.jsonapiRequest
      }
    }

    doShow()
  }

  const show = (dataOptions?: DataRequestOptions): void => {
    if (dataOptions && dataOptions.url.length > 0) {
      loadDataOptions.value = dataOptions
    }
    doShow()
  }

  const doShow = (): void => {
    if (!modalVisible.value) {
      modalVisible.value = true

      resetState()

      formDetails.value = null
      formEl.value = null

      // show modal on next render
      nextTick(() => {
        $(modalEl.value).on('hidden.bs.modal', () => {
          modalVisible.value = false
          emit('modal-hidden')
        })

        $(modalEl.value).on('shown.bs.modal', () => {
          emit('modal-shown')

          const loadOptions = loadDataOptions.value
          loadDataOptions.value = null

          if (hasForm.value) {
            loadForm()
          } else if (loadOptions) {
            loadData(loadOptions)
          }

        })

        if (!props.allowClose) {
          $(modalEl.value).on('hide.bs.modal.prevent', (e) => {
            e.preventDefault()
            emit('should-hide')
          })
        }

        // show modal
        $(modalEl.value).modal({
          backdrop: false,
          keyboard: props.allowKeyboardClose
        })
      })
    }
  }

  const hide = (): void => {
    $(modalEl.value).off('hide.bs.modal.prevent')
    $(modalEl.value).modal('hide')
  }

  const resetState = (): void => {
    hideError()
  }

  const hideError = (): void => {
    errorMessage.value = null
    errorDetails.value = []
    errorDetailsVisible.value = false
  }

  const loadForm = (): void => {
    if (formUrl.value) {
      let requestUrl = formUrl.value
      let requestData = formParams.value
      let requestMethod: 'get' | 'post' | 'GET' | 'POST' = requestData ? 'post' : 'get'
      if (formLoadRequestMethod.value) {
        requestMethod = formLoadRequestMethod.value
        if ((requestMethod === 'GET' || requestMethod === 'get') && requestData) {
          const uri = new URI(requestUrl)
          const urlParams: any = {}
          if (requestData.file_ids && Array.isArray(requestData.file_ids)) {
            urlParams['file_ids'] = requestData.file_ids.join(',')
          }
          uri.search(urlParams)
          requestUrl = uri.toString()
          requestData = null
        }
      }
      isLoading.value = true
      loadingMessage.value = $t('modal.loading_form')

      const requestHeaders: any = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-Requested-With': 'XMLHttpRequest',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-CSRF-Token': Rails.csrfToken()
      }
      if (formLoadJsonapiRequest.value) {
        requestHeaders['Content-Type'] = 'application/vnd.api+json'
        requestHeaders['Accept'] = 'application/vnd.api+json'
      }

      axios(requestUrl, {
        method: requestMethod,
        data: requestData,
        headers: requestHeaders
      })
      .then((response) => {
        const dataFormatter = new Jsona()
        const formObject = dataFormatter.deserialize(response.data)
        if (formObject) {
          setupForm(formObject)
        }
      })
      .catch((error) => {
        let errorHandled = false
        try {
          if (error.response.data) {
            const dataFormatter = new Jsona()
            const formObject = dataFormatter.deserialize(error.response.data)
            if (!Array.isArray(formObject)) {
              if (formObject && formObject.type == 'resource-form') {
                setupForm(formObject)
                errorHandled = true
              }
            }
          }
        } catch {}
        if (!errorHandled) {
          errorMessage.value = 'An error occurred while loading form'
          if (error && error.message) {
            errorDetails.value = [
              error.message
            ]
          }
        }
      })
      .then(() => {
        // self.hideLoadingIndicator()
      })
      .then(() => {
        isLoading.value = false
      })
    }
  }

  const setupForm = (formData: TJsonaModel): void => {
    formDetails.value = formData
    if (formDetails.value && formDetails.value.meta && formDetails.value.meta.error) {
      const err = formDetails.value.meta.error
      if (isPresent(err.message)) {
        errorMessage.value = err.message
        if (Array.isArray(err.details) && err.details.length > 0) {
          errorDetails.value = err.details
        }
      }
    }
    if (formDetails.value) {
      emit('form-data-loaded', formDetails.value)
      nextTick(() => {
        initForm()
      })
    }
    // this.deleteUrl = null
    // if (typeof responseData === 'string') {
    //   this.formHTML = responseData
    // } else {
    //   const status = responseData.status
    //   if (isPresent(status) && status === 'success') {
    //     // form operation succeeded
    //     this.$emit('success', responseData.candidate)
    //     return
    //   }
    //   this.formHTML = responseData.html
    //   if (isPresent(responseData.deleteUrl)) {
    //     this.deleteUrl = responseData.deleteUrl
    //   }
    // }

    // this.$nextTick(() => {
    //   this.attachToFormEvents()
    //   this.initForm()
    //   this.$emit('remoteFormLoaded')
    // })
  }

  const initForm = (): void => {
    if (formWrapEl.value) {
      formEl.value = formWrapEl.value.querySelector(':scope form[ref="inputForm"]')

      if (formEl.value) {
        initializeFormFields(formEl.value)
        formEl.value.addEventListener('ajax:success', (e: any) => {
          const [data, _status, xhr] = e.detail
          const dataFormatter = new Jsona()
          const responseObject = dataFormatter.deserialize(data)
          if (xhr.status == 201) {
            emit('created', responseObject, contextData.value)
          } else if (responseObject) {
            setupForm(responseObject)
          }
          // this.showLoadingIndicator(false)
        })

        formEl.value.addEventListener('ajax:error', (e: any) => {
          const [responseData, status, xhr] = e.detail
          let errorHandled = false
          if (xhr.status == 422) {
            const dataFormatter = new Jsona()
            const formObject = dataFormatter.deserialize(responseData)
            if (formObject) {
              errorHandled = true
              setupForm(formObject)
            }
          }
          if (!errorHandled) {
            alert(`Error: ${xhr.status} - ${status}`)
          }
        })

        emit('form-loaded', formEl.value)
      } else {
        emit('form-loaded', formWrapEl.value)
      }

      nextTick(() => {
        if (formWrapEl.value && props.autofocus) {
          autofocusInputField(formWrapEl.value)
        }
      })
    }
  }

  const submitAction = (): void => {
    resetState()
    if (formEl.value) {
      Rails.fire(formEl.value, 'submit')
    } else {
      alert('Form not initialized')
    }
  }

  const showErrorDetails = (): void => {
    errorDetailsVisible.value = true
  }

  const loadData = (requestOptions: DataRequestOptions): void => {
    if (requestOptions.url.length > 0) {
      const requestUrl = requestOptions.url
      const requestData = requestOptions.params
      const requestMethod: 'get' | 'GET' | 'post' | 'POST' = requestOptions.requestMethod || (requestData ? 'post' : 'get')

      isLoading.value = true
      loadingMessage.value = 'Loading data ...'
      axios(requestUrl, {
        method: requestMethod,
        data: requestData,
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'X-Requested-With': 'XMLHttpRequest',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'X-CSRF-Token': Rails.csrfToken()
        }
      })
      .then((response) => {
        if (requestOptions.jsonapiResponse) {
          const dataFormatter = new Jsona()
          const responseObject = dataFormatter.deserialize(response.data)
          emit('data-loaded', responseObject)
        } else {
          emit('data-loaded', response.data)
        }
      })
      .catch((error) => {
        errorMessage.value = 'An error occurred while loading data'
        if (error && error.message) {
          errorDetails.value = [
            error.message
          ]
        }
      })
      .then(() => {
        isLoading.value = false
      })
    }
  }

  const showDeleteConfirmation = (): void => {
    emit('delete-confirmation', deleteConfirmationUrl.value)
  }

  defineExpose({
    doShow,
    hide,
    show,
    showForm,
    submitAction,
  })
</script>
